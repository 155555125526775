<template>
  <div class="vps-context-menu">
    <!--    <template v-if="menu.length && menu[0].length">-->
    <template v-if="menu?.length">
      <!--      <div v-for="(group, i) in menu" :key="i" class="vps-context-menu__group">-->
      <div v-for="item in menu" :key="item.key" class="vps-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          :key="item.key"
          v-bind="item.attrs"
          class="vps-context-menu__item standart-title"
          :class="{ [`vps-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="vps-context-menu__noact standart-text">
        <div>{{ $t('actions.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import showErrorModal from '@/mixins/showErrorModal';
import modals from '@/mixins/modals';
import updateServerInfo from '@/mixins/updateServerInfo';
import Vue from 'vue';
import createBackup from '@/mixins/backup';
import Instruction from '@/layouts/VPS/components/Instruction';
import ReinstallOS from '@/layouts/VPS/components/ReinstallOS';
import redirectLink from '@/mixins/redirectLink';
export default {
  name: 'VpsContextMenu',
  mixins: [showErrorModal, updateServerInfo, createBackup, modals, redirectLink],
  props: {
    tariff: {
      type: VpsTariff,
      required: true,
      validator: obj => obj instanceof VpsTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOther: [
        {
          key: this.tariff.status.code === 1 ? 'removeOrder' : 'removeServer',
          // key: this.tariff.status.code === 1 ? 'removeOrder' : null,
          color: 'del',
          disabled: this.tariff.status.code !== 1,
          handler: this.tariff.status.code === 1 ? this.deleteFromBasket : null,
        },
      ],
      vzpanelBase: 'https://myvm.rusonyx.ru/vzpanel/instance/manage',
      pleskBase: 'https://89.253.238.44:8443/smb/web/setup',
    };
  },
  computed: {
    status() {
      return this.tariff.status.code;
    },
    licType() {
      return this.tariff.licType;
    },
    rusonyx() {
      return this.$store.state.moduleApp.host.includes('rusonyx');
    },
    infobox() {
      return this.$store.state.moduleApp.host.includes('infobox');
    },
    zenon() {
      return this.$store.state.moduleApp.host.includes('zenon');
    },
    serverId() {
      return this.tariff.serverId;
    },
    hasBackup() {
      return this.tariff && this.tariff._serverInfo && this.tariff._serverInfo.vpsBackup > 0;
    },
    serverState() {
      return this.tariff.serverState;
    },
    vmKey() {
      return this.$store.state.moduleVPS.key;
    },
    menu() {
      const stateAction = this.serverState
        ? this.serverState.toString() === 'running'
          ? 'stop'
          : 'start'
        : this.isProcessing
        ? 'process'
        : 'loading';
      const { gotoserver, panel_button } = this.tools;
      const isDisabled = !this.serverId || stateAction === 'process';
      const arr = [];
      if (gotoserver && gotoserver.isEnable(this.tariff)) {
        // TODO сделать покрасивше
        // this.provider.id !== '2' ||
        if (!this.caravan) {
          arr.push([
            {
              key: stateAction,
              handler: 'func',
              disabled: isDisabled || !gotoserver.isEnable(this.tariff) || this.isProcessing,
            },
            {
              key: 'restart',
              handler: 'func',
              disabled: isDisabled || !gotoserver.isEnable(this.tariff) || this.isProcessing,
            },
          ]);
        }
        const menu = [
          {
            key: 'panel',
            tool: gotoserver,
            disabled: !gotoserver.isEnable(this.tariff),
            payload: {
              redirect: `${this.vzpanelBase}/${this.serverId}`,
            },
          },
          {
            key: this.licType === 'ISP' ? 'isp' : 'plesk',
            tool: panel_button,
            disabled: !this.isPleskPanel || this.licType === 'none',
          },
          {
            key: 'createBackup',
            to: 'vpsBackup',
            disabled: this.caravan || !this.hasBackup,
          },
          {
            key: 'instruction',
            handler: 'func',
          },
          {
            key: 'reinstall',
            // key: this.tariff.status.code === 1 ? 'removeOrder' : null,
            color: 'del',
            disabled: this.tariff.status.code === 1,
            handler: this.reinstallOs,
          },
        ];
        arr.push(menu.filter(item => !item.disabled));
      }
      const other = this.menuOther.reduce((acc, item) => {
        if (item.show && item.show.some(i => this.tariff[i.name].toNumber() === i.value)) {
          acc.push(item);
        } else if (!item.show) acc.push(item);
        return acc;
      }, []);
      if (this.tariff.status.code === 1) arr.push(other);
      return arr.flat();
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    provider() {
      return (
        this.$store.getters['moduleProviders/current'] ||
        this.$store.getters['moduleProviders/provider']
      );
    },
    caravan() {
      // return this.provider.id === '2';
      return this.$store.state.moduleApp.host.includes('caravan');
    },
    currentProvider() {
      return this.$store.state.moduleApp.host.split('.')[1];
    },
    isPleskPanel() {
      return this.tariff.isPlesk;
    },
  },
  watch: {
    caravan() {
      return this.provider.id === '2';
    },
  },
  mounted() {
    if (this.provider.id !== '2') {
      if (!this.serverId && !this.isLoading && (this.status === 2 || this.status === 3)) {
        this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id }).then(() => {
          if (this.serverId) this.setServerInfo();
        });
      } else if (this.serverId && !this.isLoading && (this.status === 2 || this.status === 3)) {
        this.setServerInfo();
      }
    }
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.key === 'instruction') return this.openInstruction();
      if (item.handler && item.handler === 'func' && item.key !== 'instruction')
        this.askSure(item.key);
      else if (item.handler && typeof item.handler === 'function') item.handler(item);
      else if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      } else if (item.to) {
        this.createBackup(this.$t('new_backup'), {
          tariff: this.tariff,
        });
      } else if (!item.attrs && !item.to) alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action}`) }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                this.oldState = action !== 'restart' ? this.serverState.toString() : 'processing';
                time = Date.now();
                this.isProcessing = true;
                this.sendServerAction(action)
                  .then(() => {
                    res = 'success';
                    this.updateServerInfo();
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    sendServerAction(action) {
      return this.$store.dispatch('moduleVPS/setServerAction', {
        id: this.tariff.id,
        serverId: this.serverId,
        action,
      });
    },
    fetchServerInfo() {
      return this.$store.dispatch('moduleVPS/fetchServerInfo', { serverId: this.serverId });
    },
    setServerInfo() {
      const params = {
        serverId: this.serverId,
        id: this.tariff.id,
      };
      this.$store.dispatch('moduleVPS/setServerInfo', params);
    },
    getVmKey() {
      this.$store.dispatch('moduleVPS/getVmKey', this.tariff);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      let redirectLink = window.open();
      let modal = null;
      this.$modals.open({
        name: 'ToPleskPanel',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleVPS/fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            this.$modals.close();
            const url = data.ok.v + payload;
            if (redirectLink) redirectLink.location.href = url;
            else {
              this.$modals.close();
              this.showModal(url);
            }
          } else {
            this.$modals.close();
            throw new Error(
              'Не удалось перейти в плеск панель. Возможно у Вас выключен сервер. Попробуйте обратиться в техподдержку'
            );
          }
        })
        .catch(e => {
          this.$modals.close();
          this.showError(e);
        });
      // });
    },
    deleteFromBasket() {
      let modal = null;
      this.$modals.open({
        name: 'RemoveBasketResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleBasket/removeFromBasket', this.tariff.id)
        .then(() => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'text', this.$t('modal.removeFromBasket.success'));
          Vue.set(modal, 'closable', true);
          this.$store.dispatch('moduleVPS/updateList');
          if (this.$route.params.id && this.$route.params.id == this.tariff.id) {
            this.$router.push({ name: 'vpsMain' });
          }
        })
        .catch(e => this.showError(e));
    },
    reinstallOs() {
      let modal = null;
      this.$modals.open({
        name: 'ReinstallOs',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      const params = {
        func: 'vmrogue_reinstall_os',
        elid: this.tariff.id,
        // out: 'none',
        show_metadata: 'on',
      };
      this.$store
        .dispatch('moduleVPS/reinstallOs', params)
        .then(data => {
          // console.log(data);
          let self = null;
          let hasChanges = false;
          this.$modals.close();
          this.$modals.open({
            name: 'SettingsModal',
            size: 'big',
            component: ReinstallOS,
            props: data,
            on: {
              ready: () => {
                self.footer.confirm.props.disabled = false;
              },
              change: val => {
                // console.log(val);
                hasChanges = val;
              },
              submit: data => {
                const payload = { data };
                if (props.user) payload.id = props.user.id;
                Vue.set(self.props, 'formLoading', true);
                this.updateUser(payload)
                  .then(() => this.$modals.close())
                  .catch(e => {
                    // console.log(e);
                    this.showError(e);
                    Vue.set(self.props, 'formLoading', false);
                    Vue.set(self.props, 'doSubmit', false);
                    if (e.type === 'value' && e.object) {
                      Vue.set(self.props, 'errors', {
                        [e.object]: { msg: e.msg, value: data[e.object] },
                      });
                    }
                  });
              },
            },
            onOpen: instance => (self = instance),
            onClose: () => (self = null),
            footer: {
              confirm: {
                props: { title: this.$t('submit'), disabled: true },
                on: {
                  click: () => {
                    {
                      Vue.set(self.footer.confirm.props, 'loading', true);
                      let res = '';
                      let time = Date.now();
                      this.$store
                        .dispatch('moduleVPS/sendReinstallOs', {
                          ...hasChanges,
                        })
                        .then(data => {
                          // console.log(data);
                          res = 'reinstall';
                          //
                        })
                        .catch(e => {
                          res = 'fail';
                          // console.log(e);
                          this.showError(e);
                        })
                        .finally(() => {
                          Vue.set(self.footer.confirm.props, 'loading', false);
                          // console.log(res);
                          // this.$modals.close();
                          // this.showSuccessModal(res);
                          const delay = Date.now() - time < 1000 ? 1000 : 0;
                          setTimeout(() => {
                            self.component = null;
                            self.closable = true;
                            self.text = this.$t(`modal.sure.${res}`);
                            self.footer = {
                              centered: true,
                              confirm: {
                                props: { title: this.$t('modal.sure.ok') },
                                on: { click: () => this.$modals.close() },
                              },
                            };
                          }, delay);
                        });
                    }
                  },
                },
              },
              // cancel: {
              //   on: { click: () => this.$modals.dismiss() },
              // },
            },
          });
        })
        .catch(e => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'closable', true);
          this.showError(e);
        })
        .finally(() => this.$modals.close);
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    openInstruction() {
      let self = null;
      this.$modals.open({
        name: 'Instruction',
        size: 'large',
        component: Instruction,
        closeOnBackdrop: false,
        props: {
          tariff: this.tariff,
        },
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          self = null;
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "instruction": "Инструкция",
      "start": "включить",
      "stop": "выключить",
      "process": "в процессе",
      "restart": "перезагрузить",
      "reinstall": "Переустановить ОС",
      "panel": "Панель управления сервером",
      "plesk": "Панель Plesk",
      "isp": "Панель ispmanager",
      "console": "открыть консоль",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeOrder": "удалить заказ",
      "removeServer": "удалить сервер",
      "noactions": "Нет доступных действий.",
      "loading": "запрашиваем данные..."
    },
    "panel": " Панель {name}",
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "ok": "Ок",
        "reinstall": "Переустановка будет завершена в течение 2-3 минут",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    },
    "new_backup": "Создать резервную копию",
    "server_state": {
      "error_backup": "Бэкап можно сделать только на остановленном сервере!"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.vps-context-menu {

  &__group {
    & + & {
      margin-top: 1.05rem;

      +breakpoint(sm-and-up) {
        margin-top: 1.05rem;
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.05rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.vps-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
